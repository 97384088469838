import * as React from 'react'
import { graphql } from 'gatsby'
import { SEO, Page } from '@common/components'
import { Header, Post, Footer } from '../components'

export const query = graphql`
  query($slug: String) {
    post: contentfulPost(path: { eq: $slug }) {
      title
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

interface Props {
  data: { post }
  pageContext: {
    title: string
    slug: string
  }
}

const PostTemplate: React.StatelessComponent<Props> = ({
  pageContext,
  data,
}) => {
  return (
    <Page
      seoComponent={
        <SEO
          title={data.post.title}
          keywords={['basefactor', 'blog', pageContext.slug]}
        />
      }
      headerComponent={<Header />}
    >
      <Post
        title={data.post.title}
        body={data.post.body.childMarkdownRemark.html}
      />
      <Footer enableTopBackground={false} />
    </Page>
  )
}

export default PostTemplate
